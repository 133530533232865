import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class ExchangeService {

    url = 'https://sandbox.clickpesa.com/exchange';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/exchange' : 'https://api.clickpesa.com/exchange';
    }

    getAllLatest() {
        return this.http.get(`${this.url}/latest/`, this.jwt()).map((response: Response) => response.json());
    }

    getLatestByBase(base_currency) {
        return this.http.get(`${this.url}/latest/${base_currency}`, this.jwt()).map((response: Response) => response.json());
    }

    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}