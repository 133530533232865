import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class PayoutService {

    payoutAccounts = [
        {
            channel_provider: 'ECOBANK (TANZANIA)',
            branch: 'HEADQUARTER',
            swift_number: 'BIDCAQEZ',
            account_number: '7080000203',
            account_name: 'PAYCLICK LIMITED',
            currency: 'TZS'
        },
        {
            channel_provider: 'ECOBANK (TANZANIA) ',
            branch: 'HEADQUARTER',
            swift_number: 'BIDCAQEZ',
            account_number: '7080000277',
            account_name: 'PAYCLICK LIMITED',
            currency: 'USD'
        },
        {
            channel_provider: 'ECOBANK (TANZANIA)',
            branch: 'HEADQUARTER',
            swift_number: 'BIDCAQEZ',
            account_number: '7080000278',
            account_name: 'PAYCLICK LIMITED',
            currency: 'EURO'
        }
    ];

    url = 'https://sandbox.clickpesa.com';
    _user = JSON.parse(localStorage.getItem('_user'));

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com' : 'https://api.clickpesa.com';
    }
    create(payout) {
        return this.http.post(`${this.url}/payout`, payout, this.jwt()).map((response: Response) => response.json());
    }
    authorizePayout(authorizationToken) {
        let authorizationDetails = {
            payout_authorization_token: authorizationToken,
            access_token: this._user.token,
        }
        return this.http.post(`${this.url}/payout/authorize`, authorizationDetails, this.jwt()).map((response: Response) => response.json());
    }
    getById(id) {
        return this.http.get(`${this.url}/payout/` + id, this.jwt()).map((response: Response) => response.json());
    }

    update(id: string, updates: object) {
        return this.http.patch(`${this.url}/payout/${id}`, updates, this.jwt()).map((response: Response) => response.json());
    }

    getByRegistration(id) {
        return this.http.get(`${this.url}/payout/?payout_id=` + id, this.jwt()).map((response: Response) => response.json());

    }

    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        if (this._user && this._user.token) {
            let headers = new Headers({
                'Authorization': this._user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}