import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class LocalPaymentService {

    url = 'https://sandbox.clickpesa.com';
    static getUserLocalPayment: any;

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/local-payments' : 'https://api.clickpesa.com/local-payments';
    }

    getUserLocalPayment(merchantId) {
        return this.http.get(`${this.url}/merchant/${merchantId}`, this.jwt()).map((response: Response) => response.json());
    }

    getById(id) {
        return this.http.get(`${this.url}/` + id, this.jwt()).map((response: Response) => response.json());
    }

    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}