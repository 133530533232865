import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import Amplify, { Auth } from "aws-amplify";
import { ThemeComponent } from "./theme/theme.component";
import { LayoutModule } from "./theme/layouts/layout.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { AuthenticationService } from "./auth/_services/authentication.service";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-2",
    userPoolId: "us-east-2_BEIfBrod3",
    userPoolWebClientId: "1n7nj7prghlcdp8bb2p51v2m1t",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  paramName: "file", // The name that will be used to transfer the file
  maxFiles: 1,
  maxFilesize: 5, // MB
  addRemoveLinks: true,
  acceptedFiles: "image/*",
  headers: {
    "Cache-Control": null,
    "X-Requested-With": null,
  },
  previewTemplate: `<div class="dz-preview dz-file-preview">
            <div class="dz-details">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size" data-dz-size></div>
              <img data-dz-thumbnail />
            </div>
            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
          </div>`,
};

@NgModule({
  declarations: [ThemeComponent, AppComponent],
  imports: [
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeRoutingModule,
    AuthModule,
  ],
  providers: [
    ScriptLoaderService,
    AuthenticationService,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
