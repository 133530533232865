import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  isDevMode,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScriptLoaderService } from "../_services/script-loader.service";
import { AuthenticationService } from "./_services/authentication.service";
import { AlertService } from "./_services/alert.service";
import { UserService } from "./_services/user.service";
import { AlertComponent } from "./_directives/alert.component";
import { Helpers } from "../helpers";
import { JwtHelper } from "angular2-jwt";
import phone from "phone";

declare let $: any;
declare let mUtil: any;

@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
  templateUrl: "./templates/login-1.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  countries = Helpers.countryList;
  Username: string = "";
  UserPoolId: string = "";
  customerMobileNumber: string = "";

  @ViewChild("alertSignin", { read: ViewContainerRef })
  alertSignin: ViewContainerRef;
  @ViewChild("alert2fa", { read: ViewContainerRef }) alert2fa: ViewContainerRef;
  @ViewChild("alertSignup", { read: ViewContainerRef })
  alertSignup: ViewContainerRef;
  @ViewChild("alertJoin", { read: ViewContainerRef })
  alertJoin: ViewContainerRef;
  @ViewChild("alertForgotPass", { read: ViewContainerRef })
  alertForgotPass: ViewContainerRef;

  jwtHelper: JwtHelper = new JwtHelper();
  isInvite: boolean;
  invitationToken: any;
  subAccountDetails: any;
  userDetails: any;

  constructor(
    private _router: Router,
    private _script: ScriptLoaderService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver
  ) {
    if (this._authService.isAuthenticated) {
      this._authService.isAuthenticated = false;
      localStorage.clear();
      window.location.reload();
    }
    this.model.countryCode = "+255";
  }

  ngOnInit() {
    this.model.remember = true;

    this._script
      .loadScripts(
        "body",
        [
          "assets/vendors/base/vendors.bundle.js",
          "assets/demo/default/base/scripts.bundle.js",
        ],
        true
      )
      .then(() => {
        Helpers.setLoading(false);
        this.handleFormSwitch();
        this.handle2FactorAuthenticationFormSubmit();
        this.handleResendOtpCode();
        this.handleEnable2FactorAuthenticationFormSubmit();
        this.handleSignInFormSubmit();
        this.handleSignUpFormSubmit();
        this.handleForgetPasswordFormSubmit();
        if (
          window.location.pathname === "/join" &&
          this._route.snapshot.queryParams["token"]
        ) {
          return this.handleInvite();
        }
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
        this._router.navigate([this.returnUrl]);
      });
  }

  async handleInvite() {
    this.displaySignUpForm();
    this.isInvite = true;
    this.invitationToken = this._route.snapshot.queryParams["token"];
    this.userDetails = await this.verifyInvitationToken(this.invitationToken);
    this.subAccountDetails = this.userDetails.sub_accounts.find(
      (account) => account.invitation_token === this.invitationToken
    );
    this.model.email = this.subAccountDetails.email;
  }

  async verifyInvitationToken(invitationToken) {
    try {
      return await this._userService
        .verifyUserSubaccount({ invitation_token: invitationToken })
        .toPromise();
    } catch (error) {
      console.log("error", error);
      this.isInvite = false;
      this.showAlert("alertSignin");
      this._alertService.error(`${JSON.parse(error._body).message}`);
      this.loading = false;
      this.displaySignInForm();
      this.model = {};
      history.pushState({}, null, `${window.location.origin}/login`);
    }
  }

  signin() {
    this.loading = true;
    this._authService.login(this.model.email, this.model.password).subscribe(
      (inUser: any) => {
        this.loading = false;
        const { sessionedUser } = inUser;
        this.Username = sessionedUser.username;
        this.UserPoolId = sessionedUser.pool.userPoolId;
        if (sessionedUser.signInUserSession === null) {
          if (
            sessionedUser.challengeName === "CUSTOM_CHALLENGE" &&
            sessionedUser.challengeParam.challengeType === "GET_PHONE"
          ) {
            this.display2faGetPhoneForm();
            return;
          }
          if (
            sessionedUser.challengeName === "CUSTOM_CHALLENGE" &&
            sessionedUser.challengeParam.challengeType === "GET_CODE"
          ) {
            this.customerMobileNumber =
              sessionedUser.challengeParam.challengeMetadata;
            this.display2faAuthForm();
          }
          return;
        }

        if (
          sessionedUser.signInUserSession &&
          sessionedUser.signInUserSession.idToken.payload["custom:profileId"]
        ) {
          this._authService
            .getToken(sessionedUser?.signInUserSession?.idToken?.jwtToken)
            .subscribe(
              (response) => {
                if (response.success) {
                  let processEnv = isDevMode() ? "sandbox" : "live";
                  localStorage.setItem("_processEnv", processEnv);
                  localStorage.setItem("isRegistered", "true");
                  localStorage.setItem("_user", JSON.stringify(response));
                  this._router.navigate([this.returnUrl]);
                }
              },
              (error) => {
                this.loading = false;
                this.showAlert("alert2fa");
                this._alertService.error(
                  `Something went wrong!, ${error.message}`
                );
              }
            );
          return;
        }
        if (sessionedUser.signInUserSession) {
          return;
        }

        this.loading = false;
        this._router.navigate([this.returnUrl]);
      },
      (error) => {
        this.showAlert("alertSignin");
        if (error.code === "NotAuthorizedException") {
          this._alertService.error("Incorrect email or password");
        } else {
          this._alertService.error(error.message);
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        // this.display2faAuthForm();
      }
    );
  }

  _2faAddPhone() {
    this.loading = true;
    const phoneNumber = `${this.model.countryCode}${this.model._2faPhone}`;
    if (phone(phoneNumber)[0] === undefined) {
      this.showAlert("alert2AddPhone");
      this._alertService.error("Invalid phone number!");
      this.loading = false;
      return;
    }

    this.customerMobileNumber = phone(phoneNumber)[0];
    this._authService._2fAddPhone(this.customerMobileNumber).subscribe(
      (inUser: any) => {
        this.loading = false;
        const { sessionedUser, success } = inUser;
        if (success) this.display2faAuthForm();
      },
      (error) => {
        this.showAlert("alert2AddPhone");
        this._alertService.error(error.message);
        this.loading = false;
      },
      () => {}
    );
  }

  resendOtp() {
    this._authService.resendOtpCode(this.Username, this.UserPoolId).subscribe(
      (result: any) => {
        this.loading = false;
        if (result.success) {
          this.showAlert("alert2fa");
          this._alertService.success(result.message);
        } else {
          this.showAlert("alert2fa");
          this._alertService.error(result.message);
        }
      },
      (error) => {
        this.showAlert("alert2fa");
        this._alertService.error(error.message);
        this.loading = false;
      }
    );
  }

  _2fAuthenticate() {
    this.loading = true;
    this._authService._2fauthenticate(this.model._2faCode).subscribe(
      (inUser: any) => {
        this.loading = false;
        const { sessionedUser, success } = inUser;
        if (success) {
          const registrationId =
            sessionedUser.signInUserSession.idToken.payload[
              "custom:profileId"
            ] ??
            sessionedUser.signInUserSession.idToken.payload[
              "custom:parentProfileId"
            ];
          if (registrationId !== null) {
            this._authService
              .getToken(sessionedUser?.signInUserSession?.idToken?.jwtToken)
              .subscribe(
                (response) => {
                  if (response.success) {
                    let processEnv = isDevMode() ? "sandbox" : "live";
                    localStorage.setItem("_processEnv", processEnv);
                    localStorage.setItem("isRegistered", "true");
                    localStorage.setItem("_user", JSON.stringify(response));
                    if(sessionedUser.signInUserSession.idToken.payload[
                      "custom:parentProfileId"
                    ]) {
                      localStorage.setItem("isSubAccount", "true");
                    }
                    this._router.navigate([this.returnUrl]);
                  }
                },
                (error) => {
                  this.loading = false;
                  this.showAlert("alert2fa");
                  this._alertService.error(
                    `Something went wrong!, ${error.message}`
                  );
                  this._router.navigate(["/login"]);
                }
              );
          } else {
            localStorage.setItem(
              "_user",
              JSON.stringify({
                success: true,
                token: `Bearer ${sessionedUser?.signInUserSession?.idToken?.jwtToken}`,
              })
            );
            this._router.navigate([this.returnUrl]);
          }
        }
      },
      (error) => {
        this.showAlert("alert2fa");
        if (error?.code === "NotAuthorizedException") {
          this._alertService.error("Wrong SMS code entered, please try again");
        } else {
          this._alertService.error(error.message);
        }
        this.loading = false;
      },
      () => {}
    );
  }

  signup() {
    if (this.model.password != this.model.rpassword) {
      this.showAlert("alertSignup");
      this._alertService.error(
        `Password doesn't match, please check and try again.`
      );
      return;
    }
    this.loading = true;
    this._authService
      .register({
        email_address: this.model.email,
        password: this.model.password,
      })
      .subscribe(
        (data) => {
          this.showAlert("alertSignin");
          this._alertService.success(
            "Thank you. To complete your registration please check your email.",
            true
          );
          this.loading = false;
          this.displaySignInForm();
          this.model = {};
        },
        (error) => {
          this.showAlert("alertSignup");
          let is_duplicate =
            error.status === 409
              ? "Account already exist, please login or reset password."
              : error._body;
          this._alertService.error(is_duplicate ? is_duplicate : error.message);
          this.loading = false;
        }
      );
  }

  join() {
    if (!this.model.password) {
      this.showAlert("alertJoin");
      this._alertService.error(`Password is required`);
      return;
    }

    if (this.model.password != this.model.rpassword) {
      this.showAlert("alertJoin");
      this._alertService.error(
        `Password doesn't match, please check and try again`
      );
      return;
    }

    if (!this.isValidPassword(this.model.password)) {
      console.log(`Invalid password`);
      return;
    }
    this.loading = true;
    const subAccountDetails = {
      email: this.model.email,
      password: this.model.password,
      parentProfileId: this.userDetails.id,
      subAccountRole: this.subAccountDetails.role,
    };
    this._authService.subAccountRegister(subAccountDetails).subscribe(
      async (data) => {
        if (data) {
          await this._userService
            .activateSubAccount(
              {
                invitation_token: this.invitationToken,
                email: subAccountDetails.email,
                role: subAccountDetails.subAccountRole,
              },
              this.userDetails.id
            )
            .toPromise();
          this.showAlert("alertSignin");
          this._alertService.success(
            "Thank you. To complete your registration please check your email.",
            true
          );
          this.loading = false;
          this.displaySignInForm();
          this.model = {};
          history.pushState({}, null, `${window.location.origin}/login`);
        }
      },
      (error) => {
        this.showAlert("alertJoin");
        let is_duplicate =
          error.status === 409
            ? "Account already exist, please login or reset password."
            : error._body;
        this._alertService.error(is_duplicate ? is_duplicate : error.message);
        this.loading = false;
      }
    );
  }

  isValidPassword = (password: string) => {
    this.showAlert("alertJoin");

    const passwordPolicy = {
      MinimumLength: 8,
      RequireUppercase: true,
      RequireLowercase: true,
      RequireNumbers: true,
      RequireSpecialCharacter: true,
    };
    let isValid = true;

    if (!password) {
      return (isValid = false);
    }

    if (password.length < passwordPolicy.MinimumLength) {
      this.showAlert("alertJoin");
      this._alertService.error(
        `Password must be at least ${passwordPolicy.MinimumLength} characters long.`
      );
      return (isValid = false);
    }
    if (passwordPolicy.RequireNumbers && !/\d/.test(password)) {
      this.showAlert("alertJoin");
      this._alertService.error(`Password must contain at least one number.`);
      return (isValid = false);
    }
    if (
      passwordPolicy.RequireSpecialCharacter &&
      !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
    ) {
      this.showAlert("alertJoin");
      this._alertService.error(
        `Password must contain at least one special character.`
      );
      return (isValid = false);
    }
    if (passwordPolicy.RequireLowercase && !/[a-z]/.test(password)) {
      this.showAlert("alertJoin");
      this._alertService.error(
        `Password must contain at least one lowercase character.`
      );
      return (isValid = false);
    }
    if (passwordPolicy.RequireUppercase && !/[A-Z]/.test(password)) {
      this.showAlert("alertJoin");
      this._alertService.error(
        `Password must contain at least one uppercase character.`
      );
      return (isValid = false);
    }
    return isValid;
  };

  forgotPass() {
    this.loading = true;
    this._authService.forgotPassword(this.model.email).subscribe(
      (data) => {
        this.showAlert("alertSignin");
        this._alertService.success(
          "Help is on its way! Password recovery instruction has been sent to your email.",
          true
        );
        this.loading = false;
        this.displaySignInForm();
        this.model = {};
      },
      (error) => {
        this.showAlert("alertForgotPass");
        if (error.error.code === "NotAuthorizedException") {
          this._alertService.error(
            "Email does not exist, please check if correct and try again"
          );
        } else {
          this._alertService.error(error.error.message);
        }

        this.loading = false;
      }
    );
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  handleSignInFormSubmit() {
    $("#m_login_signin_submit").click((e) => {
      let form = $(e.target).closest("form");
      form.validate({
        rules: {
          email: {
            required: true,
            email: true,
          },
          password: {
            required: true,
          },
        },
      });
      if (!form.valid()) {
        e.preventDefault();
        return;
      }
    });
  }

  displaySignUpForm() {
    let login = document.getElementById("m_login");
    mUtil.removeClass(login, "m-login--forget-password");
    mUtil.removeClass(login, "m-login--signin");
    mUtil.removeClass(login, "m-login--2fa-enable");
    mUtil.removeClass(login, "m-login--2fa-auth");

    mUtil.addClass(login, "m-login--signup");
    mUtil.animateClass(
      login.getElementsByClassName("m-login__signup")[0],
      "flipInX animated"
    );
  }

  displaySignInForm() {
    let login = document.getElementById("m_login");
    mUtil.removeClass(login, "m-login--forget-password");
    mUtil.removeClass(login, "m-login--signup");
    mUtil.removeClass(login, "m-login--2fa-enable");
    mUtil.removeClass(login, "m-login--2fa-auth");
    try {
      $("form").data("validator").resetForm();
    } catch (e) {}

    mUtil.addClass(login, "m-login--signin");
    mUtil.animateClass(
      login.getElementsByClassName("m-login__signin")[0],
      "flipInX animated"
    );
  }

  display2faAuthForm() {
    let login = document.getElementById("m_login");
    mUtil.removeClass(login, "m-login--forget-password");
    mUtil.removeClass(login, "m-login--signup");
    mUtil.removeClass(login, "m-login--2fa-enable");
    mUtil.removeClass(login, "m-login--signin");
    try {
      $("form").data("validator").resetForm();
    } catch (e) {}

    mUtil.addClass(login, "m-login--2fa-auth");
    mUtil.animateClass(
      login.getElementsByClassName("m-login__2fa-auth")[0],
      "flipInX animated"
    );
  }

  display2faGetPhoneForm() {
    let login = document.getElementById("m_login");
    mUtil.removeClass(login, "m-login--forget-password");
    mUtil.removeClass(login, "m-login--signup");
    mUtil.removeClass(login, "m-login--signin");
    mUtil.removeClass(login, "m-login--2fa-auth");

    try {
      $("form").data("validator").resetForm();
    } catch (e) {}

    mUtil.addClass(login, "m-login--2fa-enable");
    mUtil.animateClass(
      login.getElementsByClassName("m-login__2fa-enable")[0],
      "flipInX animated"
    );
  }

  displayForgetPasswordForm() {
    let login = document.getElementById("m_login");
    mUtil.removeClass(login, "m-login--signin");
    mUtil.removeClass(login, "m-login--signup");
    mUtil.removeClass(login, "m-login--2fa-auth");

    mUtil.addClass(login, "m-login--forget-password");
    mUtil.animateClass(
      login.getElementsByClassName("m-login__forget-password")[0],
      "flipInX animated"
    );
  }

  handleFormSwitch() {
    document
      .getElementById("m_login_forget_password")
      .addEventListener("click", (e) => {
        e.preventDefault();
        this.displayForgetPasswordForm();
      });

    document
      .getElementById("m_login_forget_password_cancel")
      .addEventListener("click", (e) => {
        e.preventDefault();
        this.displaySignInForm();
      });

    document.getElementById("m_login_signup").addEventListener("click", (e) => {
      e.preventDefault();
      this.displaySignUpForm();
    });

    document
      .getElementById("m_login_signup_cancel")
      .addEventListener("click", (e) => {
        e.preventDefault();
        this.displaySignInForm();
      });
  }

  handleSignUpFormSubmit() {
    document
      .getElementById("m_login_signup_submit")
      .addEventListener("click", (e) => {
        let btn = $(e.target);
        let form = $(e.target).closest("form");

        $.validator.addMethod(
          "hasCapitalCase",
          function (value: any) {
            const paramRegex: RegExp = /[A-Z]/;
            return paramRegex.test(value);
          },
          `Must contains at least one uppercase character`
        );

        $.validator.addMethod(
          "hasSmallCase",
          function (value: any) {
            const paramRegex: RegExp = /[a-z]/;
            return paramRegex.test(value);
          },
          `Must contains at least one lowercase character`
        );

        $.validator.addMethod(
          "hasNumber",
          function (value: any) {
            const paramRegex: RegExp = /\d/;
            return paramRegex.test(value);
          },
          `Must contains at least one number`
        );

        $.validator.addMethod(
          "hasSpecialCharacters",
          function (value: any) {
            const paramRegex: RegExp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            return paramRegex.test(value);
          },
          `Must contains at least one special character`
        );

        form.validate({
          rules: {
            /*  country: {
                        required: true,
                    }, */
            email: {
              required: true,
              email: true,
            },
            password: {
              required: true,
              minlength: 8,
              hasCapitalCase: true,
              hasSmallCase: true,
              hasNumber: true,
              hasSpecialCharacters: true,
            },
            rpassword: {
              required: true,
            },
            agree: {
              required: true,
            },
          },

          messages: {
            rpassword: {
              equalTo: "Please enter the same password as above",
            },
          },
        });

        if (!form.valid()) {
          e.preventDefault();
          return;
        }
      });
  }

  handleForgetPasswordFormSubmit() {
    document
      .getElementById("m_login_forget_password_submit")
      .addEventListener("click", (e) => {
        let btn = $(e.target);
        let form = $(e.target).closest("form");
        form.validate({
          rules: {
            email: {
              required: true,
              email: true,
            },
          },
        });
        if (!form.valid()) {
          e.preventDefault();
          return;
        }
      });
  }

  handleEnable2FactorAuthenticationFormSubmit() {
    document
      .getElementById("m_login_enable_2faAuth_submit")
      .addEventListener("click", (e) => {
        let btn = $(e.target);
        let form = $(e.target).closest("form");
        form.validate({
          rules: {
            _2faPhone: {
              required: true,
            },
          },
        });
        if (!form.valid()) {
          e.preventDefault();
          return;
        }
      });
  }

  handle2FactorAuthenticationFormSubmit() {
    document
      .getElementById("m_login_2faAuth_submit")
      .addEventListener("click", (e) => {
        let btn = $(e.target);
        let form = $(e.target).closest("form");
        form.validate({
          rules: {
            _2faCode: {
              required: true,
            },
          },
        });
        if (!form.valid()) {
          e.preventDefault();
          return;
        }
      });
  }

  handleResendOtpCode() {
    document
      .getElementById("m_login_2faResend_otp")
      .addEventListener("click", (e) => {
        let btn = $(e.target);
        let form = $(e.target).closest("form");
        // form.validate({
        //   rules: {
        //     _2faCode: {
        //       required: true,
        //     },
        //   },
        // });
        if (!form.valid()) {
          e.preventDefault();
          return;
        }
      });
  }
}
