import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class CustomerService {

    url = 'https://sandbox.clickpesa.com/customer/';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/customer/' : 'https://api.clickpesa.com/customer/';
    }
    create(customer) {
        return this.http.post(`${this.url}`, customer, this.jwt()).map((response: Response) => response.json());
    }
    getById(id) {
        return this.http.get(`${this.url}` + id, this.jwt()).map((response: Response) => response.json());
    }
    getByCustomerWalletAddress(walletAddress) {
        return this.http.get(`${this.url}?account_address=` + walletAddress, this.jwt()).map((response: Response) => {
            let formatedResult = response.json()
            return formatedResult[0];
        });
    }
    update(id: string, updates: object) {
        return this.http.patch(`${this.url + id}`, updates, this.jwt()).map((response: Response) => response.json());
    }

    getUserCustomers(id) {
        return this.http.get(`${this.url}?anchor_id=` + id, this.jwt()).map((response: Response) => response.json());
    }


    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}