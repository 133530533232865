import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { UserService } from '../../../auth/_services';
import { JwtHelper } from 'angular2-jwt';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    isRegistered;
    jwtHelper: JwtHelper = new JwtHelper();
    user_token = JSON.parse(localStorage.getItem("_user"));
    current_user = this.jwtHelper.decodeToken(this.user_token.token);
    isSubAccount: string;
    //isBusiness: boolean = false;

    constructor(public _userService: UserService) {
        this.isRegistered = localStorage.getItem('isRegistered');
        this.isSubAccount = JSON.parse(localStorage.getItem('isSubAccount'));
    }

    async ngOnInit() {
        if(!this._userService.userAccount && this.current_user.id){
            const userAccount = await this.getUserAccount();
            this._userService.businessAddress = userAccount[1]??{};
            this._userService.userAccount = userAccount[0]??{};
            //this.isBusiness = businessAddress?.is_business??false;
        }
    }

    getUserAccount() {
        return new Promise((resolve) => {
            this._userService.getUserAccount(this.current_user.id)
                .subscribe(res => {
                    resolve(res)
                })
        });
    }

    ngAfterViewInit() {
        mLayout.initAside();

    }

}