import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';

const routes: Routes = [
    {
        'path': '',
        'component': ThemeComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'getting-started',
                'loadChildren': '.\/pages\/default\/getting-started\/getting-started.module#GettingStartedModule',
            },
            {
                'path': 'index',
                'loadChildren': '.\/pages\/default\/blank\/blank.module#BlankModule',
            },
            {
                'path': 'create-invoice',
                'loadChildren': '.\/pages\/default\/e-invoice\/create-invoice\/create-invoice.module#CreateInvoiceModule',
            },
            {
                'path': 'edit-invoice',
                'loadChildren': '.\/pages\/default\/e-invoice\/edit-invoice\/edit-invoice.module#EditInvoiceModule',
            },
            {
                'path': 'einvoice',
                'loadChildren': '.\/pages\/default\/e-invoice\/transactions-e-invoice\/transactions-e-invoice.module#TransactionsEInvoiceModule',
            },
            {
                'path': 'einvoice-details',
                'loadChildren': '.\/pages\/default\/e-invoice\/single-transaction-e-invoice\/single-transaction-e-invoice.module#SingleTransactionEInvoiceModule',
            },
            {
                'path': 'send-invoice',
                'loadChildren': '.\/pages\/default\/e-invoice\/invoice-template\/invoice-template.module#InvoiceTemplateModule',
            },
            {
                'path': 'bulk-payment-batch-list',
                'loadChildren': '.\/pages\/default\/bulk-payments\/bulk-payment-batch-list\/bulk-payment-batch-list.module#BulkPaymentBatchListModule',
            },
            {
                'path': 'batch-details',
                'loadChildren': '.\/pages\/default\/bulk-payments\/single-bulk-payment-batch\/single-bulk-payment-batch.module#SingleBulkPaymentBatchModule',
            },
            {
                'path': 'single-batch-payment-detail',
                'loadChildren': '.\/pages\/default\/bulk-payments\/single-batch-payment-details\/single-batch-payment-details.module#SingleBatchPaymentDetailModule',
            },
            {
                'path': 'upload-bulk-file',
                'loadChildren': '.\/pages\/default\/bulk-payments\/upload-bulk-file\/upload-bulk-file.module#UploadBulkFileModule',
            },
            {
                'path': 'single-transaction-payment-acceptance',
                'loadChildren': '.\/pages\/default\/payment-acceptance\/single-transaction-payment-acceptance\/single-transaction-payment-acceptance.module#SingleTransactionPaymentAcceptanceModule',
            },
            {
                'path': 'transactions-payment-acceptance',
                'loadChildren': '.\/pages\/default\/payment-acceptance\/transactions-payment-acceptance\/transactions-payment-acceptance.module#TransactionsPaymentAcceptanceModule',
            },
            {
                'path': 'payment-acceptance-setup',
                'loadChildren': '.\/pages\/default\/payment-acceptance\/payment-acceptance-setup\/payment-acceptance-setup.module#PaymentAcceptanceSetupModule',
            },
            {
                'path': 'payment-request',
                'loadChildren': '.\/pages\/default\/payment-page\/payment-request\/payment-request.module#PaymentRequestModule',
            },
            {
                'path': 'all-payment-requests',
                'loadChildren': '.\/pages\/default\/payment-page\/payment-requests\/payment-requests.module#PaymentRequestsModule',
            },
            {
                'path': 'payment-request-detail',
                'loadChildren': '.\/pages\/default\/payment-page\/payment-request-detail\/payment-request-detail.module#PaymentRequestDetailModule',
            },
            {
                'path': 'create-payment-page',
                'loadChildren': '.\/pages\/default\/payment-page\/create-payment-page\/create-payment-page.module#CreatePaymentPageModule',
            },
            {
                'path': 'draft-payment-page',
                'loadChildren': '.\/pages\/default\/payment-page\/draft-payment-page\/draft-payment-page.module#DraftPaymentPageModule',
            },
            {
                'path': 'single-payment-page',
                'loadChildren': '.\/pages\/default\/payment-page\/single-payment-page\/single-payment-page.module#SinglePaymentPageModule',
            },
            {
                'path': 'single-payment-page-transaction',
                'loadChildren': '.\/pages\/default\/payment-page\/single-payment-page-transaction\/single-payment-page-transaction.module#SinglePaymentPageTransactionModule',
            },
            {
                'path': 'payment-pages',
                'loadChildren': '.\/pages\/default\/payment-page\/payment-pages\/payment-pages.module#PaymentPagesModule',
            },
            
            {
                'path': 'exchange',
                'loadChildren': '.\/pages\/default\/ebureau-de-change\/convert\/convert.module#ConvertModule',
            },
            {
                'path': 'deposit',
                'loadChildren': '.\/pages\/default\/deposit\/deposit.module#DepositModule',
            },
            {
                'path': 'single-transaction-ebureau-de-change',
                'loadChildren': '.\/pages\/default\/ebureau-de-change\/single-transaction-ebureau-de-change\/single-transaction-ebureau-de-change.module#SingleTransactionEbureauDeChangeModule',
            },
            {
                'path': 'transactions-ebureau',
                'loadChildren': '.\/pages\/default\/ebureau-de-change\/transactions-ebureau-de-change\/transactions-ebureau.module#TransactionsEBureauModule',
            },
            {
                'path': 'settings',
                'loadChildren': '.\/pages\/default\/settings\/settings\/settings.module#SettingsModule',
            },
            {
                'path': 'user-settings',
                'loadChildren': '.\/pages\/default\/settings\/user-settings\/user-settings.module#UserSettingsModule',
            },
            {
                'path': 'developer-settings',
                'loadChildren': '.\/pages\/default\/settings\/developer-settings\/developer-settings.module#DeveloperSettingsModule',
            },
            {
                'path': 'change-password',
                'loadChildren': '.\/pages\/default\/settings\/change-password\/change-password.module#ChangePasswordModule',
            },
            {
                'path': 'notifications-settings',
                'loadChildren': '.\/pages\/default\/settings\/notifications\/notifications.module#NotificationsModule',
            },
            {
                'path': 'payment-settings',
                'loadChildren': '.\/pages\/default\/settings\/payment-settings\/payment-settings.module#PaymentSettingsModule',
            },
            {
                'path': 'customer',
                'loadChildren': '.\/pages\/default\/customers\/customers-list\/customers-list.module#CustomersListModule',
            }, {
                'path': 'single-customer',
                'loadChildren': '.\/pages\/default\/customers\/single-customer\/single-customer.module#SingleCustomerModule',
            }, {
                'path': 'dashboard',
                'loadChildren': '.\/pages\/default\/dashboard\/dashboard.module#DashboardModule',
            }, {
                'path': 'wallet',
                'loadChildren': '.\/pages\/default\/payout\/payout\/payout.module#PayoutModule',
            }, {
                'path': 'payment/detail',
                'loadChildren': '.\/pages\/default\/payout\/single-transaction-payout\/single-transaction-payout.module#SingleTransactionPayoutModule',
            },
            {
                'path': 'deposit/detail',
                'loadChildren': '.\/pages\/default\/payout\/single-deposit-detail\/single-deposit-detail.module#singleDepositDetailModule',
            },
            {
                'path': 'withdrawal/detail',
                'loadChildren': '.\/pages\/default\/payout\/single-withdrawal-detail\/single-withdrawal-detail.module#singleWithdrawalDetailModule',
            },
            {
                'path': '',
                'redirectTo': 'index',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'index',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule { }