import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class InvoiceService {

    url = 'https://sandbox.clickpesa.com/invoice/';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/invoice/' : 'https://api.clickpesa.com/invoice/';
    }
    create(invoice) {
        return this.http.post(`${this.url}`, invoice, this.jwt()).map((response: Response) => response.json());
    }
    getById(id) {
        return this.http.get(`${this.url}` + id, this.jwt()).map((response: Response) => response.json());
    }
    getAllInvoices() {
        return this.http.get(`${this.url}`, this.jwt()).map((response: Response) => response.json());
    }
    sendInvoice(id) {
        return this.http.post(`${this.url}${id}/send`,{}, this.jwt()).map((response: Response) => response.json());
    }

    markAsPaid(id, payout_info) {
        return this.http.post(`${this.url}${id}/payout/verify`, payout_info, this.jwt()).map((response: Response) => response.json());
    }

    update(id: string, updates: object) {
        return this.http.patch(`${this.url + id}`, updates, this.jwt()).map((response: Response) => response.json());
    }

    getUserInvoices(user_id) {
        return this.http.get(`${this.url}?merchant_id=` + user_id, this.jwt()).map((response: Response) => response.json());
    }

    deleteInvoice(id) {
        return this.http.delete(`${this.url}${id}`, this.jwt()).map((response: Response) => response.json());
    }



    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}