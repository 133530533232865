import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseRequestOptions, HttpModule } from '@angular/http';
import { MockBackend } from '@angular/http/testing';

import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { AlertComponent } from './_directives/alert.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { CustomerService } from './_services/customer.service';
import { InvoiceService } from './_services/invoice.service';
import { ExchangeService } from './_services/exchange.service';
import { BureauService } from './_services/bureau.service';
import { WalletService } from './_services/wallet.service';
import { PayoutService } from './_services/payout.service';
import { PaymentPageService } from './_services/payment-page.service';
import { LocalPaymentService } from './_services/local-payment.service';
import { DepositRequestService } from './_services/deposit-request.service';
import { PaymentService, BulkPaymentsService } from './_services';

@NgModule({
    declarations: [
        AuthComponent,
        AlertComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        AuthRoutingModule,
    ],
    providers: [
        AuthGuard,
        AlertService,
        //AuthenticationService,
        UserService,
        CustomerService,
        InvoiceService,
        ExchangeService,
        BureauService,
        WalletService,
        PayoutService,
        PaymentPageService,
        DepositRequestService,
        PaymentService,
        LocalPaymentService,
        BulkPaymentsService,
        // api backend simulation
        MockBackend,
        BaseRequestOptions,
    ],
    entryComponents: [AlertComponent],
})

export class AuthModule {
}