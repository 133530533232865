import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class PaymentPageService {

    url = 'https://sandbox.clickpesa.com/payment-page/';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/payment-page/' : 'https://api.clickpesa.com/payment-page/';
    }
    createPaymentPage(pageDetails) {
        return this.http.post(`${this.url}`, pageDetails, this.jwt()).map((response: Response) => response.json());
    }
    togglePaymentStatus(id) {
        return this.http.put(`${this.url}` + id, this.jwt()).map((response: Response) => response.json());
    }
    getById(id) {
        return this.http.get(`${this.url}` + id, this.jwt()).map((response: Response) => response.json());
    }

    update(id: string, updates: object) {
        return this.http.patch(`${this.url + id}`, updates, this.jwt()).map((response: Response) => response.json());
    }

    getUserPaymentPages(user_id) {
        return this.http.get(`${this.url}?creator_id=` + user_id, this.jwt()).map((response: Response) => response.json());
    }

    deletePaymentPage(id) {
        return this.http.delete(`${this.url}${id}`, this.jwt()).map((response: Response) => response.json());
    }



    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}