import { Injectable, isDevMode } from "@angular/core";
import { Http, Headers, Response, RequestOptions } from "@angular/http";
import "rxjs/add/operator/map";
import { Observable, Subscriber } from "rxjs/Rx";
import { Auth } from "aws-amplify";

@Injectable()
export class AuthenticationService {
  url = "https://sandbox.clickpesa.com";
  isAuthenticated: boolean = false;
  sessionedUser: any = {};

  constructor(private http: Http) {
    this.url = isDevMode()
      ? "https://sandbox.clickpesa.com"
      : "https://api.clickpesa.com";
  }

  login(email: string, password: string) {
    let authenticationFlowType = "CUSTOM_AUTH";
    let migrationData = {};
    return new Observable((subscriber) => {
      this.initiateAuthMigration(email, password)
        .then((response) => {
          if (response === null) {
            this.attemptCognitoSignIn(
              { email, password },
              authenticationFlowType,
              migrationData,
              subscriber
            );
          }
          if (response?.registration_id) {
            authenticationFlowType = "USER_PASSWORD_AUTH";
            migrationData = {
              merchantId: response?.registration_id,
              emailVerified: "true",
            };
            this.attemptCognitoSignIn(
              { email, password },
              authenticationFlowType,
              migrationData,
              subscriber
            );
          }
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    });
  }

  attemptCognitoSignIn(
    credentials,
    authenticationFlowType,
    migrationData,
    subscriber: Subscriber<any>
  ) {
    try {
      Auth.configure({
        authenticationFlowType: authenticationFlowType,
      });
      Auth.signIn(credentials.email, credentials.password, migrationData)
        .then((response) => {
          this.sessionedUser = response;

          subscriber.next({
            success: true,
            sessionedUser: this.sessionedUser,
          });
        })
        .catch((error) => {
          subscriber.error(error);
        });
    } catch (error) {
      subscriber.error(error);
    }
  }

  resendOtpCode(Username: string, UserPoolId: string) {
    return this.http
      .post("https://staging.authentication.clickpesa.com/2fa/resend-otp", {
        UserPoolId,
        Username,
      })
      .map((response: Response) => response.json());
  }

  _2fAddPhone(_2faPhone: string) {
    if (
      this.sessionedUser.challengeName === "CUSTOM_CHALLENGE" &&
      this.sessionedUser.challengeParam.challengeType === "GET_PHONE"
    ) {
      return new Observable((subscriber) => {
        Auth.sendCustomChallengeAnswer(this.sessionedUser, _2faPhone)
          .then((response) => {
            this.sessionedUser = response;

            subscriber.next({
              success: true,
              sessionedUser: this.sessionedUser,
            });
          })
          .catch((error) => {
            subscriber.error(error);
          });
      });
    }
  }

  _2fauthenticate(_2factorCode: string) {
    if (
      this.sessionedUser.challengeName === "CUSTOM_CHALLENGE" &&
      this.sessionedUser.challengeParam.challengeType === "GET_CODE"
    ) {
      const Username = this.sessionedUser.username;
      const UserPoolId = this.sessionedUser.pool.userPoolId;

      return new Observable((subscriber) => {
        this.validateCode(_2factorCode, Username, UserPoolId).subscribe(
          (response) => {
            if (!response.success) {
              return subscriber.error(response);
            }
            Auth.sendCustomChallengeAnswer(this.sessionedUser, _2factorCode)
              .then(async (response) => {
                this.sessionedUser = response;
                this.isAuthenticated = true;

                return subscriber.next({
                  success: true,
                  sessionedUser: this.sessionedUser,
                });
              })
              .catch((error) => {
                return subscriber.error(error);
              });
          },
          (error) => {
            return subscriber.error(error);
          }
        );
      });
    }
  }

  confirmPassword(password, user_id) {
    return new Observable((subscriber) => {
      return subscriber.next(this.isAuthenticated);
    });
  }

  register(user) {
    const email = user.email_address;
    const password = user.password;
    return new Observable((subscriber) => {
      Auth.signUp({
        username: email,
        password,
        clientMetadata: {
          returnURL: `${window.location.origin}/login`,
        },
      })
        .then((response) => {
          subscriber.next(response);
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  }
  subAccountRegister(subAccountDetails) {
    const { email, password, parentProfileId, subAccountRole } =
      subAccountDetails;
    return new Observable((subscriber) => {
      Auth.signUp({
        username: email,
        password,
        attributes: {
          "custom:profileType": "SUB-ACCOUNT",
          "custom:parentProfileId": parentProfileId,
          "custom:subAccountRole": subAccountRole,
        },
        clientMetadata: {
          returnURL: `${window.location.origin}/login`,
        },
      })
        .then((response) => {
          subscriber.next(response);
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  }

  logout() {
    return new Observable((subscriber) => {
      Auth.signOut()
        .then((response) => {
          subscriber.next(response);
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  }

  confirmEmailVerificationStatus(email) {
    return this.http
      .post(`${this.url}/confirm-email-verification/${email}`, this.headers())
      .map((response: Response) => response.json());
  }

  resendVerificationEmail(email) {
    return this.http
      .post(`${this.url}/resend-verification-token/${email}`, this.headers())
      .map((response: Response) => response.json());
  }

  initiateAuthMigration(email_address: string, password: string) {
    return this.http
      .post(`${this.url}/auth/migration`, { email_address, password })
      .map((response: Response) => response.json())
      .toPromise();
  }

  forgotPassword(email: string) {
    return new Observable((subscriber) => {
      const clientMetadata: any = {
        returnURL: `${window.location.origin}/login`,
      };
      Auth.forgotPassword(email, clientMetadata).then(
        (result) => {
          subscriber.next({
            success: true,
            result,
          });
        },
        (error) => {
          subscriber.error({
            success: false,
            error,
          });
        }
      );
    });
  }

  validateCode(otp: string, Username: string, UserPoolId: string) {
    return this.http
      .post("https://staging.authentication.clickpesa.com/2fa/valid-otp", {
        UserPoolId,
        Username,
        otp,
      })
      .map((response: Response) => response.json());
  }

  getToken(idToken: string) {
    return this.http
      .post(`${this.url}/auth/access-token`, {
        userIdToken: idToken,
      })
      .map((response: Response) => response.json());
  }

  // private helper methods
  private headers() {
    let headers = new Headers({ "Content-Type": "application/json" });
    return new RequestOptions({ headers: headers });
  }
}
