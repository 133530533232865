import { Router } from "@angular/router";
import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/forkJoin";
import { User } from "../_models/index";
import { flatMap, map } from "rxjs/operators";

@Injectable()
export class UserService {
  url = "https://sandbox.clickpesa.com";
  UserInfo: Observable<any>;
  userAccount: any;
  businessAddress: any = null;
  authServerUrl: string;
  stellarHorizonURL: string;
  constructor(private http: Http, private _router: Router) {
    this.url = isDevMode()
      ? "https://sandbox.clickpesa.com"
      : "https://api.clickpesa.com";
    this.authServerUrl = isDevMode()
      ? "https://staging.authentication.clickpesa.com/merchants/update-profileid"
      : "https://authentication.clickpesa.com/merchants/update-profileid";
    this.stellarHorizonURL = isDevMode()
      ? "https://horizon-testnet.stellar.org"
      : "https://horizon.stellar.org";
  }
  verify() {
    return this.http
      .get("/api/verify", this.jwt())
      .map((response: Response) => response.json());
  }

  forgotPassword(email: string) {
    return this.http
      .post(`${this.url}/request-password-reset/${email}`, this.jwt())
      .map((response: Response) => response.json());
  }

  _2fauthenticate(_2factorCode: string) {
    console.log("CODE: ", _2factorCode);
    return this.http
      .post(`${this.url}/2-factor-auth/${_2factorCode}`, this.jwt())
      .map((response: Response) => response.json());
  }

  getAll() {
    return this.http
      .get("/api/users", this.jwt())
      .map((response: Response) => response.json());
  }

  getById(id) {
    return this.http
      .get(`${this.url}/merchant/` + id, this.jwt())
      .map((response: Response) => response.json());
  }

  create(user) {
    return this.http
      .post(`${this.url}/merchant`, user, this.jwt())
      .map((response: Response) => response.json());
  }

  update(id: string, updates: object) {
    return this.http
      .patch(`${this.url}/merchant/${id}`, updates, this.jwt())
      .map((response: Response) => response.json());
  }

  delete(id: number) {
    return this.http
      .delete("/api/users/" + id, this.jwt())
      .map((response: Response) => response.json());
  }

  updateCognitoWithProfileId(data) {
    return this.http
      .post(
        `https://staging.authentication.clickpesa.com/merchants/update-profileid`,
        data
      )
      .map((response: Response) => response.json());
  }

  getUserAccount(id) {
    if (id) {
      let account = this.http
        .get(`${this.url}/accounts/?registration_id=` + id, this.jwt())
        .pipe(
          flatMap((response: any) => {
            const { balances, ...rest } = response.json();
            const accountInfo = this.http
              .get(`${this.stellarHorizonURL}/accounts/${rest.publicKey}`)
              .pipe(
                map((horizonResponse: any) => {
                  return {
                    ...rest, // Include all properties from the 'rest' object
                    balances: horizonResponse.json().balances, // Add latestWalletBalance
                  };
                })
              );
            return accountInfo;
          })
        );
      account.subscribe(
        (results) => {},
        (error) => {
          if (error.status == 401) {
            this._router.navigate(["/logout"]);
          }
        }
      );
      let user = this.http
        .get(`${this.url}/merchant/` + id, this.jwt())
        .map((response: Response) => response.json());
      this.UserInfo = user;
      return Observable.forkJoin(account, user);
    }
  }

  notifyUserOfPasswordChange(data) {
    return this.http
      .post(this.authServerUrl, data)
      .map((response: Response) => response.json());
  }

  addUserSubaccount(userId, subAccountDetails) {
    return this.http
      .post(`${this.url}/merchant/add-sub-account/${userId}`,subAccountDetails,this.jwt())
      .map((response: Response) => response.json());
  }

  verifyUserSubaccount(subAccountDetails) {
    return this.http
      .post(`${this.url}/merchant/verify-sub-account`,subAccountDetails,this.jwt())
      .map((response: Response) => response.json());
  }

  activateSubAccount(subAccountDetails, user_id) {
    return this.http
      .post(
        `${this.url}/merchant/activate-sub-account/${user_id}`,
        subAccountDetails,
        this.jwt()
      )
      .map((response: Response) => response.json());
  }

  // private helper methods
  private jwt() {
    // create authorization header with jwt token
    let _user = JSON.parse(localStorage.getItem("_user"));
    if (_user && _user.token) {
      let headers = new Headers({
        Authorization: _user.token,
        "Content-Type": "application/json",
      });
      return new RequestOptions({ headers: headers });
    }
  }
}
