import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";


@Injectable()
export class DepositRequestService {

  baseUrl;
  constructor(private http: Http) {
    this.baseUrl = isDevMode() ? 'https://sandbox.clickpesa.com/deposit/' : 'https://api.clickpesa.com/deposit/';
  }
  create(depositInfo) {
    return this.http.post(this.baseUrl, depositInfo, this.jwt()).map((response: Response) => response.json());
  }

  // private helper methods
  private jwt() {
    // create authorization header with jwt token
    let currentUser = JSON.parse(localStorage.getItem('_user'));
    if (currentUser && currentUser.token) {
      let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
      headers.append('Content-Type', 'application/json')
      return new RequestOptions({ headers: headers });
    }
  }
}
