import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { UserService } from '../../../auth/_services';
import { JwtHelper } from 'angular2-jwt';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

    jwtHelper: JwtHelper = new JwtHelper();
    user_token = JSON.parse(localStorage.getItem("_user"));
    current_user = this.jwtHelper.decodeToken(this.user_token.token);
    user_details: any = {};
    businessInitials: any;
    businessLogo: string;
    hasLogo: boolean;
    userWallet: any;
    isSubAccount: any;

    constructor(
        public _userService: UserService
    ) {
        this.isSubAccount = JSON.parse(localStorage.getItem('isSubAccount'));

    }
    ngOnInit() {
        if (this.current_user.id) {
            this.getUserInfo();
            this.getUserWallet();
        }
    }
    ngAfterViewInit() {
        mLayout.initHeader();
    }

    getUserInfo() {
        this._userService.getById(this.current_user.id)
            .subscribe(result => {
                if (!result) return;
                let business_name
                let type
                if (result.is_business) {
                    business_name = result.business.business_name
                    type = 'A Registered Business'
                    this.user_details.is_business = true;
                }
                else {
                    business_name = result.first_name + ' ' + result.last_name
                    type = 'A Sole Trader'
                }
                this.user_details = {
                    ...result,
                    business_name: business_name,
                    type: type,
                    business_description: result.business.business_description || `${result.is_business ? "We're" : "I'm"} dealing with ...`,
                    website: result.business.website || 'Not set'
                }
                this.businessInitials = this.user_details.business_name.split(" ")[0].charAt(0) +
                    (this.user_details.business_name.split(" ")[1] ? this.user_details.business_name.split(" ")[1].charAt(0) : "");
                this.businessLogo = "https://res.cloudinary.com/clickpesa/image/upload/v1575460997/" + this.user_details.business_logo + ".png";
                this.hasLogo = result.business_logo ? true : false
            },
                error => { }
            );
    }

    getUserWallet() {
        this._userService.getUserAccount(this.current_user.id)
            .subscribe(res => {
                let user_account = res[0]??{};
                this.userWallet = user_account.account_address;
                this._userService.userAccount = user_account;
            });
    }

}