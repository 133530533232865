import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class WalletService {

    url = 'https://sandbox.clickpesa.com';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com' : 'https://api.clickpesa.com';
    }
    getById(id) {
        return this.http.get(`${this.url}/accounts/` + id, this.jwt()).map((response: Response) => response.json());
    }

    update(id: string, updates: object) {
        return this.http.patch(`${this.url}/accounts/${id}`, updates, this.jwt()).map((response: Response) => response.json());
    }
    getByWalletAddress(walletAddress) {
        return this.http.get(`${this.url}/accounts/?account_address=` + walletAddress, this.jwt()).map((response: Response) => response.json());
    }
    getWalletDepositTransactions(customerId) {
        return this.http.get(`${this.url}/deposit-request/?customer_id=` + customerId, this.jwt()).map((response: Response) => response.json());
    }

    getWalletWithdrawalTransactions(customerId) {
        return this.http.get(`${this.url}/payout/?customer_id=` + customerId, this.jwt()).map((response: Response) => response.json());
    }

    getWalletReceivedPaymentsTransactions(userWalletAddress) {
        return this.http.get(`${this.url}/payment/?receiver_address=` + userWalletAddress, this.jwt()).map((response: Response) => response.json());
    }

    getSinglePayment(paymnetId) {
        return this.http.get(`${this.url}/payment/` + paymnetId, this.jwt()).map((response: Response) => response.json());
    }
    getSingleDeposit(depositId) {
        return this.http.get(`${this.url}/deposit-request/` + depositId, this.jwt()).map((response: Response) => response.json());
    }
    getSingleWithdrawal(withdrawalId) {
        return this.http.get(`${this.url}/payout/` + withdrawalId, this.jwt()).map((response: Response) => response.json());
    }
    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}