import * as $ from "jquery";
import * as CountryList from '../country-list.json';
import { Country } from './auth/_models';

export class Helpers {

    static countryList: Country[] = JSON.parse(JSON.stringify(CountryList));
    static paymentRequestStatusStyle = {
        'PAID': { 'title': 'paid', 'class': ' m-badge--success' },
        'PENDING': { 'title': 'pending', 'class': ' m-badge--warning' },
        'FAILED': { 'title': 'failed', 'class': ' m-badge--danger' },
    };


    static loadStyles(tag, src) {
        if (Array.isArray(src)) {
            $.each(src, function(k, s) {
                $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
            });
        } else {
            $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
        }
    }

    static unwrapTag(element) {
        $(element).removeAttr('appunwraptag').unwrap();
    }

	/**
	 * Set title markup
	 * @param title
	 */
    static setTitle(title) {
        $('.m-subheader__title').text(title);
    }

	/**
	 * Breadcrumbs markup
	 * @param breadcrumbs
	 */
    static setBreadcrumbs(breadcrumbs) {
        if (breadcrumbs) $('.m-subheader__title').addClass('m-subheader__title--separator');

        let ul = $('.m-subheader__breadcrumbs');

        if ($(ul).length === 0) {
            ul = $('<ul/>').addClass('m-subheader__breadcrumbs m-nav m-nav--inline')
                .append($('<li/>').addClass('m-nav__item')
                    .append($('<a/>').addClass('m-nav__link m-nav__link--icon')
                        .append($('<i/>').addClass('m-nav__link-icon la la-home'))));
        }

        $(ul).find('li:not(:first-child)').remove();
        $.each(breadcrumbs, function(k, v) {
            let li = $('<li/>').addClass('m-nav__item')
                .append($('<a/>').addClass('m-nav__link m-nav__link--icon').attr('routerLink', v.href).attr('title', v.title)
                    .append($('<span/>').addClass('m-nav__link-text').text(v.text)));
            $(ul).append($('<li/>').addClass('m-nav__separator').text('-')).append(li);
        });
        $('.m-subheader .m-stack__item:first-child').append(ul);
    }

    static setLoading(enable) {
        let body = $('body');
        if (enable) {
            $(body).addClass('m-page--loading-non-block')
        } else {
            $(body).removeClass('m-page--loading-non-block')
        }
    }

    static bodyClass(strClass) {
        $('body').attr('class', strClass);
    }

    static formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    static reFormatDate(aDate: any) {
        const date = new Date(aDate);
        return date.toDateString();
    }

    static getPaymentRequestStatus = (requestStatus: string) => {
        return Helpers.paymentRequestStatusStyle[requestStatus];
    }

    static getPaymentRequestStatusStyle = (requestStatus: string) => {
        const statusSyle = Helpers.getPaymentRequestStatus(requestStatus);
        return  '<span class="m-badge text-white ' + statusSyle?.class + ' m-badge--wide">' + statusSyle?.title + '</span>';
    }

    static getInvoiceDueDate(date1, date2) {
        //Get 1 day in milliseconds
        var one_day = 1000 * 60 * 60 * 24;
        // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();
        // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;
        // Convert back to days and return
        let days = Math.round(difference_ms / one_day);
        // Create attaching text
        if (days == 0) {
            return 'are due tommorow'
        }
        let text = days > 1 ? `are due within ${days} days` : `are due within a day`
        return days < 0 ? 'are due today' : text
    }

    static getClickpesaAccounts(currency) {
        let accounts = [
            {
                channel_provider: 'ECOBANK (TANZANIA)',
                branch: 'HEADQUARTER',
                swift_number: 'ECOCTZTZ',
                account_number: '7080000203',
                account_name: 'PAYCLICK LIMITED',
                currency: 'TZS'
            },
            {
                channel_provider: 'ECOBANK (TANZANIA)',
                branch: 'HEADQUARTER',
                swift_number: 'ECOCTZTZ',
                account_number: '7080000277',
                account_name: 'PAYCLICK LIMITED',
                currency: 'USD'
            },
            {
                channel_provider: 'ECOBANK (TANZANIA)',
                branch: 'HEADQUARTER',
                swift_number: 'ECOCTZTZ',
                account_number: '7080000278',
                account_name: 'PAYCLICK LIMITED',
                currency: 'EURO'
            }
        ]
        return accounts.filter(account => account.currency == currency)[0]
    }

    static getBank() {
        return {
            "ACCESS BANK TANZANIA LTD": "ACTZTZTZ",
            "ADVANS BANK TANZANIA": "ADVBTZTZ",
            "AFRICAN BANKING CORPORATION TANZANIA LIMITED": "FMBZTZTX",
            "AKIBA COMMERCIAL BANK LTD": "ZAKCOTZTZ",
            "AMANA BANK LIMITED": "AMNNTTZ",
            "AZANIA BANCORP": "AZANTZTZ",
            "AZANIA BANK LIMITED": "ADBCTZT1",
            "BANK M TANZANIA LIMITED": "BNKMTZTZ",
            "BANK OF BARODA (TANZANIA) LTD": "BARBTZTZ",
            "BANK OF INDIA (TANZANIA) LIMITED": "BKIDTZTZ",
            "BANK OF TANZANIA": "TANZTZTX",
            "BARCLAYS BANK TANZANIA LTD": "BARCTZTZ",
            "BOA BANK TANZANIA": "EUAFTZTZ",
            "CITIBANK TANZANIA LTD": "CITITZTZ",
            "COMMERCIAL BANK OF AFRICA (TANZANIA) LTD": "CBAFTZTZ",
            "COVENANT BANK FOR WOMEN (TANZANIA) LIMITED": "CBFWTZTZ",
            "CRDB BANK LIMITED": "CORUTZTZ",
            "CROWN FINANCE AND LEASING LIMITED": "CFLLTZT1",
            "DAR ES SALAAM COMMUNITY BANK LTD": "DASUTZTZ",
            "DIAMOND TRUST BANK TANZANIA LTD": "DTKETZTZ",
            "EAST AFRICAN DEVELOPMENT BANK": "AFDETZT1",
            "ECOBANK TANZANIA LIMITED": "ECOCTZTZ",
            "EFATHA BANK LIMITED": "EFABTZTZ",
            "EQUITY BANK TANZANIA LIMITED": "EQBLTZTZ",
            "EXIMBANK (TANZANIA) LTD": "EXTNTZTZ",
            "FBME BANK LTD": "FBMETZTZ",
            "FIRST NATIONAL BANK OF TANZANIA": "FIRNTZTX",
            "HABIB AFRICAN BANK": "HABLTZTZ",
            "I AND M BANK (T) LIMITED": "CFUBTZTZ",
            "INTERNATIONAL COMMERCIAL BANK (TANZANIA) LIMITED": "BKMYTZTZ",
            "KARADHA COMPANY LTD": "KACLTZT1",
            "KCB BANK TANZANIA LIMITED": "KCBLTZTZ",
            "KILIMANJARO CO OPERATIVE BANK LTD": "KLMJTZTZ",
            "MAENDELEO BANK LTD": "MBTLTZT1",
            "MKOMBOZI COMMERCIAL BANK LTD": "MKCBTZTZ",
            "MWANGA COMMUNITY BANK LTD": "MWCBTZTZ",
            "NATIONAL BANK OF COMMERCE THE (HEADOFFICE)": "NLCBTZTX",
            "NATIONAL MICROFINANCE BANK LIMITED": "NMIBTZTZ",
            "NIC BANK (TANZANIA) LIMITED": "SFICTZTZ",
            "ORBIT SECURITES CO LTD": "ORSUTZT1",
            "PEOPLES BANK OF ZANZIBAR THE ZANZIBAR": "PBZATZTZ",
            "SABMILLER TANZANIA": "SABMTZT1",
            "STANBIC BANK TANZANIA LTD.": "SBICTZTX",
            "STANDARD CHARTERED BANK TANZANIA LTD": "SCBLTZTX",
            "STATE BANK OF INDIA": "SBINTZT1",
            "TANZANIA HOUSING BANK (CITY BRANCH)": "TAHOTZT1",
            "TANZANIA POSTAL BANK": "TAPBTZTZ",
            "TANZANIA REVENUE AUTHORITY": "TARATZTZ",
            "TANZANIA WOMENS BANK LTD": "TWBLTZTZ",
            "TIB DEVELOPMENT BANK LIMITED": "TAINTZTZ",
            "TRUST BANK (TANZANIA) LIMITED": "TRBATZT1",
            "TWIGA BANCORP LIMITED": "TWIGTZTZ",
            "UBL BANK (TANZANIA) LIMITED": "UNILTZTZ",
            "UCHUMI COMMERCIAL BANK LTD": "UCCTTZTZ",
            "ULC (TANZANIA) LTD": "ULCTTZT1",
            "UNITED BANK FOR AFRICA (TANZANIA) LIMITED": "UNAFTZTZ"
        }
    }


}