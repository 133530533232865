import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../_services";
import { Observable } from "rxjs/Rx";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _authService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user) {
            this._authService.isAuthenticated = true;
            return true
        }
        else {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        /*  return this._userService.verify().map(
             data => {
                 if (data !== null) {
                     // logged in so return true
                     return true;
                 }
                 // error when verify so redirect to login page with the return url
                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                 return false;
             },
             error => {
                 // error when verify so redirect to login page with the return url
                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                 return false;
             }); */
    }
}