import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import "rxjs/add/observable/forkJoin";

@Injectable()
export class PaymentService {
  url = "https://sandbox.clickpesa.com";
  _user = JSON.parse(localStorage.getItem("_user"));

  constructor(private http: Http) {
    this.url = isDevMode()
      ? "https://sandbox.clickpesa.com"
      : "https://api.clickpesa.com";
  }

  create(payment: any) {
    return this.http
      .post(`${this.url}/payment`, payment, this.jwt())
      .map((response: Response) => response.json());
  }
  requestPayment(paymentRequestDetails: any) {
    return this.http
      .post(`${this.url}/payment-request`, paymentRequestDetails, this.jwt())
      .map((response: Response) => response.json());
  }
  getUserPaymentRequests(userAddress: String) {
    return this.http
      .get(`${this.url}/payment-request?from=` + userAddress, this.jwt())
      .map((response: Response) => response.json());
  }
  getPaymentRequestById(id: String) {
    return this.http
      .get(`${this.url}/payment-request/${id}`, this.jwt())
      .map((response: Response) => response.json());
  }
  deletePaymentRequest(id: String) {
    return this.http
      .delete(`${this.url}/payment-request/${id}`, this.jwt())
      .map((response: Response) => response.json());
  }
  getAuthorizationToken(paymentId) {
    return this.http
      .post(
        `${this.url}/payment/request-authorization-token/${paymentId}`,
        {},
        this.jwt()
      )
      .map((response: Response) => response.json());
  }
  authorizePayment(authorizationToken) {
    let authorizationDetails = {
      authorization_token: authorizationToken,
      access_token: this._user.token,
    };
    return this.http
      .post(
        `${this.url}/payment/authorize-payment`,
        authorizationDetails,
        this.jwt()
      )
      .map((response: Response) => response.json());
  }
  getById(id) {
    return this.http
      .get(`${this.url}/payment/` + id, this.jwt())
      .map((response: Response) => response.json());
  }

  update(id: string, updates: object) {
    return this.http
      .patch(`${this.url}/payment/${id}`, updates, this.jwt())
      .map((response: Response) => response.json());
  }

  getByRegistration(id) {
    return this.http
      .get(`${this.url}/payment/?payment_id=` + id, this.jwt())
      .map((response: Response) => response.json());
  }

  // private helper methods
  private jwt() {
    // create authorization header with jwt token
    if (this._user && this._user.token) {
      let headers = new Headers({
        Authorization: this._user.token,
        "Content-Type": "application/json",
      });
      return new RequestOptions({ headers: headers });
    }
  }
}
