import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Injectable()
export class BureauService {

    url = 'https://sandbox.clickpesa.com';

    constructor(private http: Http) {
        this.url = isDevMode() ? 'https://sandbox.clickpesa.com/bureau/' : 'https://api.clickpesa.com/bureau/';
    }
    create(bureau) {
        return this.http.post(`${this.url}`, bureau, this.jwt()).map((response: Response) => response.json());
    }
    getById(id) {
        return this.http.get(`${this.url}` + id, this.jwt()).map((response: Response) => response.json());
    }

    update(id: string, updates: object) {
        return this.http.patch(`${this.url + id}`, updates, this.jwt()).map((response: Response) => response.json());
    }

    getUserBureauTransactions(user_id) {
        return this.http.get(`${this.url}?customer_id=` + user_id, this.jwt()).map((response: Response) => response.json());
    }

    delete(id) {
        return this.http.delete(`${this.url}${id}`, this.jwt()).map((response: Response) => response.json());
    }


    // private helper methods
    private jwt() {
        // create authorization header with jwt token
        let _user = JSON.parse(localStorage.getItem('_user'));
        if (_user && _user.token) {
            let headers = new Headers({
                'Authorization': _user.token,
                'Content-Type': 'application/json'
            });
            return new RequestOptions({ headers: headers });
        }
    }
}