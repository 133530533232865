import { Injectable, isDevMode } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/forkJoin";

@Injectable()
export class BulkPaymentsService {
  url = "https://sandbox.clickpesa.com";

  constructor(private http: Http) {
    this.url = isDevMode()
      ? "https://sandbox.clickpesa.com/bulk-payments/"
      : "https://api.clickpesa.com/bulk-payments/";
  }
  create(batch) {
    return this.http
      .post(`${this.url}`, batch, this.jwt())
      .map((response: Response) => response.json());
  }
  addBatchPayment(batch_id, payments) {
    let details = {
      batch_id: batch_id,
      payments: payments,
    };
    return this.http
      .post(`${this.url}payments`, details, this.jwt())
      .map((response: Response) => response.json());
  }
  getBatchPayments(batch_id) {
    return this.http
      .get(`${this.url}payments/${batch_id}`, this.jwt())
      .map((response: Response) => response.json());
  }
  getById(id) {
    return this.http
      .get(`${this.url}` + id, this.jwt())
      .map((response: Response) => response.json());
  }
  getByUserId(id) {
    return this.http
      .get(`${this.url}?merchant=` + id, this.jwt())
      .map((response: Response) => response.json());
  }

  delete(id) {
    return this.http
      .delete(`${this.url}${id}`, this.jwt())
      .map((response: Response) => response.json());
  }

  confirmBatchPayout(batch_id) {
    return this.http
      .post(`${this.url}confirm-batch-payouts/${batch_id}`, {}, this.jwt())
      .map((response: Response) => response.json());
  }

  // private helper methods
  private jwt() {
    // create authorization header with jwt token
    let _user = JSON.parse(localStorage.getItem("_user"));
    if (_user && _user.token) {
      let headers = new Headers({
        Authorization: _user.token,
        "Content-Type": "application/json",
      });
      return new RequestOptions({ headers: headers });
    }
  }
}
